.embed-card {
  max-width: 280px;
  padding: 20px 20px 8px 20px;
  background-color: #FFF;
  border-radius: 5px;
  border-bottom: 2px solid #CCC;
  margin: 15px;

  iframe {
    height: 150px;
  }
}

.sc-link {
  color: #222;
  font-weight: normal;
  font-size: 15px;
  text-decoration: none;
  display: block;
  padding-top: 6px;
}

.embed-card-caption {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
  font-weight: 100;
}
