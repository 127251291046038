header {
  padding: 60px 0;
}

header > * {
  color: #FFF;   
}

#brand {
  font-family: 'Berkshire Swash', Arial, Helvetica, sans-serif;
  font-size: 80px;
}

#tagline {
  font-size: 25px;
  font-weight: 300;
  margin-top: 15px;
}

#links {
  padding: 60px;
  display: flex;
  justify-content: center;
  background-color: #FFF;
}

#links > * {
  width: 20%;
  margin: 0 10px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 2.5vw;
  font-weight: 300;
}

.link a {
  color: #222;
  text-decoration: none;
  transition: linear 0.1s;
}

.link a:hover {
  font-size: 3vw;
  transition: linear 0.1s;
}

header .logo {
  fill: #FFF;
  display: none;
}

nav .logo {
  fill: #222222;
  width: 20%;
}

@media (max-width: 768px) {
  header {
      padding: 20px 0;
  }
  
  header .logo {
      display: inherit;
      margin: auto;
      margin-top: 20px;
  }

  nav .logo {
      display: none;
  }

  #links {
      padding: 20px 0;
      background-color: #FFF;
      display: block;
  }

  #links > * {
      width: auto;
  }

  .link {
      display: inherit;
      padding: 10px;
  }

  .link a, .link a:hover {
      font-size: 35px;
  }
}