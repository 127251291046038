@import './styles/reset.scss';

body {
  background-color: #222;
}

html * {
  font-family: Roboto, sans-serif;
  text-align: center;
}

h1, h2, h3, h4, h5, h6, p {
  color: #222222;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Alata, sans-serif;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 45px;
  font-weight: 300;
}

#featured-tracks h2 {
  color: #FFF;
  margin: 30px 0 20px;
}

#sc-embeds {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

section {
  padding-bottom: 40px;
}

footer {
  padding: 20px;
  font-weight: 300;
  background-color: #FFF;
}